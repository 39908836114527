<template>
<div>
  <p></p><h5 align="center" style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;text-align:center"><p align="center" style="margin: 0cm 0cm 8pt; line-height: 107%; font-size: 10pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 12pt; line-height: 107%;">서비스 이용약관</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">1</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">목적</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">본 약관은<span lang="EN-US"> (</span>주<span lang="EN-US">)</span>테이슨<span lang="EN-US">(</span>이하<span lang="EN-US"> '</span>회사<span lang="EN-US">')</span>가 운영하는
<span lang="EN-US">DRONEWORK </span>서비스를 이용함에 있어<span lang="EN-US"> '</span>회사<span lang="EN-US">'</span>와 회원의 권리와 의무<span lang="EN-US">, </span>책임사항 및 기타 필요한 사항을 정함을 목적으로
합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">2</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">용어의 정의</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">본 약관에서 사용하는 용어의 뜻은 다음 각 호와 같습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①<span lang="EN-US">'DRONEWORK </span>서비스<span lang="EN-US">'</span>라
함은 회사가 본 이용 약관에 따른 서비스를 회원에게 제공하기 위하여 구축한 플랫폼으로서<span lang="EN-US"> Mobile
Application, Mobile Web, </span>인터넷 사이트 등의 형태로 드론과 현장인력의 운영관리 서비스를 제공하는 사업과 향후 관련되는
회사를 영위하게 될 제반 사업 일체 및 운영을 위한 서비스를 말하며 회사는 다음과 같은 업무를 수행합니다<span lang="EN-US">. </span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">1. </span>드론을 활용한 서비스</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">2. </span>회사에서 제공하는 재화 또는 용역에 대한 정보 제공 서비스 본
조의 서비스 이외에도 추가적인 서비스를 개발하여 회원에게 제공할 수 있습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">②회원이라 함은 본 약관에 대한 동의 절차를 거쳐 회원 가입을 통해 회사가 제공하는 서비스를 이용할 수 있는
권한을 부여받은 대상을 말합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">&nbsp;1.</span>회원이란
회사에 회원으로 가입하고<span lang="EN-US">, </span>회사가 제공하는 서비스를 이용할 수 있는 자격을 가진 회원을 의미합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">③<span lang="EN-US">'</span>홈페이지<span lang="EN-US">(APP/WEB)'</span>라
함은 회원 계정<span lang="EN-US">(ID </span>및 비밀번호<span lang="EN-US">)</span>을 이용하여 모든 서비스를
제공받을 수 있는<span lang="EN-US"> 'APP'</span>과 회사가 운영하는 <span lang="EN-US">'WEB'</span>을
말합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">④본 조에서 정의되지 않은 약관 상의 용어의 의미는 일반적인 거래 관행을 따릅니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">3</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">약관 게시와
개정</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①회사는 본 약관의 내용을 회원이 쉽게 확인할 수 있도록<span lang="EN-US"> '</span>홈페이지<span lang="EN-US">(APP/WEB)'</span>에 게시합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">②회사는 본 약관의 내용을 수시로 개정 가능하며 약관을 개정할 경우 개정 내용을 명시하여 적용일자<span lang="EN-US"> 7</span>일 전부터 적용일 이후 일정 기간 동안<span lang="EN-US">, </span>개정 내용이 회원에게 불리한
경우에는 그 적용일자<span lang="EN-US"> 30</span>일 전부터 적용일 이후 일정 기간 동안<span lang="EN-US">,
E-mail </span>통보<span lang="EN-US">, </span>홈페이지<span lang="EN-US">(APP/WEB), </span>서면
통보<span lang="EN-US">, </span>사업장 및 제휴 가맹점 내 게시 등의 방법 중<span lang="EN-US"> 1</span>가지
이상의 방법으로 회원에게 고지합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">③회사가 회원에게<span lang="EN-US"> E-mail, </span>서면 통보의 방법으로 약관 개정 사실
및 내용을 개별 고지하는 경우에<span lang="EN-US">, </span>회원이 제공한 이메일과 주소 중 가장 최근에 제공된 곳으로 통보해야
하며 회원이 최근의 정보로 변경하지 않아 발생한 손해에 대해서는 어떠한 책임도 지지 않습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">④회원이 개정 약관의 적용에 동의하지 않는 경우<span lang="EN-US"> '</span>회사<span lang="EN-US">'</span>는 개정 약관의 내용을 적용할 수 없으며<span lang="EN-US">, </span>이 경우 회원은 회원 탈퇴를
할 수 있습니다<span lang="EN-US">. </span>단<span lang="EN-US">, </span>이의가 있음에도 불구하고 본 조<span lang="EN-US"> 2</span>항에 명시된 고지일자부터 적용 일자 이내에 회원 탈퇴를 하지 않을 경우 개정 약관에 동의한 것으로 간주합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">⑤본 약관에 동의하는 것은<span lang="EN-US"> '</span>회사<span lang="EN-US">'</span>가
운영하는<span lang="EN-US"> '</span>홈페이지<span lang="EN-US">(APP/WEB)'</span>를 방문하여 정기적으로
약관의 변경 사항을 확인하는 것에 동의함을 의미하며<span lang="EN-US">, </span>변경된 약관에 대한 정보를 알지 못하여 발생하는
회원의 손해에 대하여 책임을 부담하지 않습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">4</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">약관 해석과
효력</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①규정에 의하여 개정된 약관<span lang="EN-US">(</span>이하<span lang="EN-US">
'</span>개정 약관<span lang="EN-US">')</span>은 원칙적으로 그 효력 발생일로부터 장래에 향하여 유효하며<span lang="EN-US">, </span>본 조 규정의 통지 효력은 각 조항에서 규정하는 개별적<span lang="EN-US">, </span>전체적인
통지의 경우에도 이를 준용합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">②회사는 특정 서비스에 대해서는 별도의 약관<span lang="EN-US">(</span>이하<span lang="EN-US"> '</span>개별약관<span lang="EN-US">') </span>및 정책<span lang="EN-US">(</span>이하<span lang="EN-US"> '</span>개별정책<span lang="EN-US">')</span>을 둘 수 있으며<span lang="EN-US">, </span>이를<span lang="EN-US"> '</span>홈페이지 <span lang="EN-US">(APP/WEB)'</span>를 통해 공지할 수 있습니다<span lang="EN-US">. </span>해당 내용이 본 약관과 충돌할 경우에는<span lang="EN-US"> '</span>개별약관<span lang="EN-US">'</span>이 우선하여 적용됩니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">③회사는 필요한 경우 서비스 이용과 관련된 세부적인 내용<span lang="EN-US">(</span>이하<span lang="EN-US"> '</span>이용정책<span lang="EN-US">'</span>이라 함<span lang="EN-US">)</span>을
정하여 이를<span lang="EN-US"> '</span>홈페이지<span lang="EN-US">(APP/WEB)' </span>등을 통하여 공지할
수 있습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">5</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">약관 외
준칙</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">①</span>본 약관에 명시되지 않은 사항에 대해서는<span lang="EN-US"> '</span>정보통신망 이용촉진 및 정보보호 등에 관한 법률<span lang="EN-US">', '</span>전자문서 및
전자거래기본법<span lang="EN-US">', '</span>전자상거래 등에서의 소비자보호에 관한 법률<span lang="EN-US">', '</span>약관의
규제에 관한 법률<span lang="EN-US">', '</span>전자서명법<span lang="EN-US">', </span>등의 관계 법령 및<span lang="EN-US"> '</span>회사<span lang="EN-US">'</span>가 정한 서비스의 세부이용지침 등의 규정에 의합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">6</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">회원가입
및 계약의 성립</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①회원이 되고자 하는 자는<span lang="EN-US"> '</span>회사<span lang="EN-US">'</span>가
정한 가입 절차에 의해 본 약관을 포함한<span lang="EN-US">, '</span>개인정보 처리방침<span lang="EN-US">', '</span>위치
서비스 이용약관<span lang="EN-US">'</span>에 동의함으로써 회원가입을 신청합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">②회사는 본 조<span lang="EN-US"> 1</span>항과 같이 회원으로 가입할 것을 신청한 이용자 중
다음 각 호에 해당하지 않는 한 회원으로 등록합니다<span lang="EN-US">. </span>다만<span lang="EN-US">, </span>회사는
다음 각 호에 해당하는 회원가입 신청에 대하여는 승낙을 하지 않거나<span lang="EN-US">, </span>사후에 회원가입 계약을 해지할
수 있습니다<span lang="EN-US">. </span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">1. </span>최소 가입 조건이 충족되지 않는 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">2. </span>가입신청자가 본 약관에 의하여 이전에 회원 자격을 상실한 적이
있는 경우<span lang="EN-US"> (</span>단<span lang="EN-US">, </span>회사의 회원 재가입<span lang="EN-US">)</span>승낙을 얻은 경우에는 예외로 함</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">3. </span>가입신청자의 실명이 아니거나<span lang="EN-US">,
</span>타인의 명의를 이용한 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">4. </span>회사가 제시하는 내용을 기재하지 않았거나<span lang="EN-US">, </span>허위의 정보를 기재한 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">5. </span>회원으로 등록하는 것이 원활한 서비스 운영을 저해하거나<span lang="EN-US">, </span>기술상 현저히 지장이 있다고 판단되는 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">6. </span>기타 법령 기타 국가기관의 지침에 따른 경우 </span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">③</span>회원가입 계약 성립 시기는 홈페이지<span lang="EN-US">(APP/WEB) </span>신청 절차 상에서 완료를 표시한 시점으로 합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">④회사는 회원에 대해<span lang="EN-US"> '</span>이용 정책 및 개별 정책<span lang="EN-US">'</span>에 따라 서비스 이용에 차등을 둘 수 있습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">⑤회사는 서비스 관련 설비의 여유가 없거나<span lang="EN-US">, </span>기술상 또는 업무상 문제가
있는 경우에는 승낙을 유보 또는 취소할 수 있습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">7</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">회원의</span><span lang="EN-US" style="font-size: 10pt;"> ID </span><span style="font-size: 10pt;">및 비밀번호에 대한 책임과 의무</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①계정 정보<span lang="EN-US">(ID </span>및 비밀번호<span lang="EN-US">)</span>에
관한 관리 책임은 회원에게 있으며<span lang="EN-US">, </span>회원은 자신의 계정 정보를 제<span lang="EN-US">3</span>자에게
제공하지 아니하고<span lang="EN-US"> 3</span>개월 마다 비밀번호 변경하여 관리의 의무를 다 합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">②회원이 자신의 계정 정보를 도난당하거나 제<span lang="EN-US">3</span>자가 이용하고 있음을
인지한 경우<span lang="EN-US">, </span>즉시 회사에 알려야 하며 별도의 안내가 있는 경우 이에 따라야 합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">③회사는 회원이 본 조항의 주의사항을 소홀히 하여 발생한 회원의 어떠한 불이익에 대하여도 책임을 지지 아니합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US" style="font-weight: normal;">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">8</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">회원 탈퇴
및 자격상실</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①회원은 홈페이지<span lang="EN-US">(APP/WEB)</span>를 통해 언제든지 직접 탈퇴가 가능하며
회사는 회원의 요청에 따라 서면<span lang="EN-US">, E-mail, </span>전화<span lang="EN-US">, </span>기타
방법으로 접수된 회원 탈퇴 요구사항에 대해 보유하고 있는 모든 정보를 개인정보보호방침에 따라 삭제 합니다<span lang="EN-US">. </span>단<span lang="EN-US">, </span>다음 각 호의 어느 하나에 해당하는 경우 탈퇴 처리가 거절되거나<span lang="EN-US">, </span>별도
지침에 의해 조치될 수 있습니다<span lang="EN-US">. </span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">1. </span>회원이 회사에 대한 금전적인 채무가 존재하는 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">2. </span>사고처리 등과 같이 회원의 책임 있는 행위를 기반으로 업무 처리가
완료되지 않은 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">3. </span>이용 정지 상태에서 탈퇴를 요청하는 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">②</span>회원탈퇴 완료 후<span lang="EN-US"> 90</span>일
이내에는 재가입이 제한될 수 있습니다<span lang="EN-US">. </span>단<span lang="EN-US">, </span>회사와 회원
간에 사전 협의한 경우에는 회원 재가입이 가능합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">③회원이 다음 각호의 어느 하나에 해당하는 경우<span lang="EN-US">, </span>회사는 회원 자격을
상실시킬 수 있습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">1. </span>다른 사람의 정보를 불법적으로 취득하거나 도용하는 등 전자거래
질서를 위협하는 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">2. </span>비정상적인 방법으로 다른 사람의 서비스 이용을 방해하거나<span lang="EN-US">, </span>허위 및 거짓 제보를 통해 서비스의 정상적인 운영을 지속 저해하는 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">3. </span>관계 법령에 위반되거나 공서 양속에 반하는 행위를 하는 등 회원자격을
유지시키는 것이 부적절하다고 판단되는 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">4. </span>마지막 서비스 이용일로부터<span lang="EN-US"> 3</span>년간
서비스 이용 이력이 없는 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">5. </span>페널티 제도의 적발 사례가 고의적이거나 여러 차례 반복되는 경우
</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">④</span>회원은 본 조 제<span lang="EN-US"> 3</span>항에
따른 자격상실에 대해<span lang="EN-US"> '</span>회사<span lang="EN-US">'</span>가 정한 절차에 따라 이의</span> <span style="font-weight: normal;">신청을
할 수 있으며<span lang="EN-US">, </span>회사는 회원의 이의 신청이 정당하다고 판단되는 경우 즉시 회원 자격을 재개합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">⑤회원이 사망한 경우 회원 사망일에 회원 자격이 상실됩니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US" style="font-weight: normal;">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">9</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">회원의 서비스
이용제한</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:125%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①회사는
본 약관 제<span lang="EN-US">10</span>조를 포함하여 회원이 본 약관 및 개별 약관의 의무를 위반하거나 서비스의
정상적인 운영을 방해한 경우<span lang="EN-US">, </span>경고<span lang="EN-US">, </span>일시정지 및 영구정지
등으로 서비스 이용을 제한할 수 있습니다<span lang="EN-US">. </span>회원의 자격 재심사의 과정은 해당 회원의 서비스 이용 내역을
토대로 진행되며<span lang="EN-US">, </span>회사의 내부 심사 결과에 따라 서비스 이용 자격 제한 및 해제가 이뤄질 수 있습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">②회사는 본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 본 약관 제<span lang="EN-US">11</span>조에 의거하여 통지합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">③</span>회원은 본 조에 따른 이용제한 조치에 대해 회사가 정한 절차에 따라
이의 신청을 할 수 있으며<span lang="EN-US">, </span>회사는 회원의 이의 신청이 정당하다고 판단되는 경우 즉시 회원 자격을 재개합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US" style="font-weight: normal; font-size: 10pt;">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">10</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">회원에
대한 통지</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①회사가 회원에 대한 통지를 하는 경우<span lang="EN-US">, </span>개별 안내를 제외 시 제<span lang="EN-US">3</span>조<span lang="EN-US">2</span>항에 규정된 방법 가운데<span lang="EN-US"> 1</span>가지
이상으로 당해 사항을 통지할 수 있습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">②회사는 불특정다수 회원에 대한 통지의 경우<span lang="EN-US"> 1</span>주일이상 회사 홈페이지<span lang="EN-US">(APP/WEB) </span>공지사항에 게시함으로써 개별 통지에 갈음 할 수 있습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">11</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">회원의
의무</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①회원은 다음 각 호에 해당하는 행위를 하여서는 안 됩니다<span lang="EN-US">. </span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">1. </span>서비스 이용 관련 제반 신청 및 변경 시 허위 내용 기재</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">2. </span>타인의 정보를 도용하는 행위</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">3. </span>회사가 게시한 각종 정보의 무단 변경<span lang="EN-US">, </span>삭제 등 훼손 행위</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">4. </span>회사가 허용한 정보 이외의 다른 정보<span lang="EN-US">(</span>컴퓨터 프로그램 및 광고 등<span lang="EN-US">)</span>를 송신하거나 게시하는 행위</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">5. </span>회사 및 제<span lang="EN-US">3</span>자의
지적재산권 침해 또는 명예를 손상하거나 업무를 방해하는 행위</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">6. </span>외설적<span lang="EN-US">, </span>폭력적인
음성<span lang="EN-US">, </span>메시지<span lang="EN-US">, </span>영상 등 기타 공서양속에 반하는 언행 및
정보를 홈페이지<span lang="EN-US">(APP/WEB)</span>에 공개<span lang="EN-US">, </span>게시하거나<span lang="EN-US">, '</span>회사의 고객센터<span lang="EN-US">' </span>이용 시 발언하는 행위 </span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">②회원은 본 약관의 규정<span lang="EN-US">, '</span>개별 약관<span lang="EN-US">' </span>및<span lang="EN-US"> '</span>개별 정책<span lang="EN-US">', </span>회사가
통지한 주의사항<span lang="EN-US">, </span>관련 법령 등을 준수하여야 하며<span lang="EN-US">, </span>기타
회사의 원활한 서비스 제공에 방해되는 행위를 하여서는 안됩니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">③회원은 본 조항의 의무를 다하지 않을 경우 서비스 이용제한 또는 회원 자격이 상실될 수 있습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">④회원은 본 약관 및<span lang="EN-US"> '</span>개별약관<span lang="EN-US">'</span>을
준수하지 않아 발생하는 회사의 모든 재산상의 손실이나 제<span lang="EN-US">3</span>자에게 끼친 인적<span lang="EN-US">, </span>물적 손실에 대하여 배상할 책임이 있습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">12</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">회사의
의무</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①회사는 본 약관 및 관련법이 금지하거나 공서양속에 반하는 행위를 하지 않으며<span lang="EN-US">,
</span>지속적이며 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">②회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보<span lang="EN-US">(</span>신용정보
포함<span lang="EN-US">)</span>보호를 위해 보안시스템을 갖추어야 하며<span lang="EN-US"> '</span>개인정보처리방침<span lang="EN-US">'</span>을 공시하고 준수합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">③회사는 회원의 서비스 이용에 대한 안내<span lang="EN-US">, </span>서비스 개진 의견<span lang="EN-US">, </span>기타 문의사항에 대한 응대를 위해 온라인 상담 채널 및 유선 고객센터<span lang="EN-US">(</span>이하<span lang="EN-US"> '</span>테이슨 고객센터<span lang="EN-US">')</span>를 상시 운영합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">④회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야
합니다<span lang="EN-US">. </span>회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 이메일 등을 통하여 회원에게
처리과정 및 결과를 전달합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">13</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">서비스의
변경 및 중단</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①회사는 사업 종목의 전환<span lang="EN-US">, </span>업체 간의 통합<span lang="EN-US">, </span>사업 폐지 등 기타 회사의 판단에 의해 불가피한 영업상의 이유가 있다고 인정되는 경우 제공하는 서비스의 내용을
변경할 수 있습니다<span lang="EN-US">. </span>이 경우에는 변경된 서비스의 내용 및 제공 일자를 명시하여 서비스 내용을 게시한
곳에 그 제공 일자의<span lang="EN-US"> 30</span>일 전부터 공지합니다<span lang="EN-US">. </span>단<span lang="EN-US">, </span>변동 내용을 구체적으로 공지하기가 불가능한 경우에는<span lang="EN-US"> 30</span>일 전에
그 취지 및 공지가 불가능한 사유를<span lang="EN-US"> '</span>홈페이지<span lang="EN-US">(APP/WEB)' </span>내
공지합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">②회사는 다음 각 호의 어느 하나에 해당하는 사유가 발생한 경우 제<span lang="EN-US">2</span>조<span lang="EN-US">1</span>항 각 호에 명시된 서비스의 일부 또는 전부를 중단할 수 있습니다<span lang="EN-US">. </span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">1. </span>천재지변<span lang="EN-US">, </span>전쟁<span lang="EN-US">, </span>폭동<span lang="EN-US">, </span>화재<span lang="EN-US">, </span>파업 등
쟁의행위<span lang="EN-US">, </span>정부기관의 통제 기타<span lang="EN-US"> '</span>회사<span lang="EN-US">'</span>의 합리적인 노력으로 제어할 수 없는 사유가 발생하거나 발생할 우려가 있는 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">2. </span>기간통신사업자로부터 전기통신서비스가 제공되지 않은 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">3. </span>회사의 시스템을 포함한 정보통신설비의 보수점검<span lang="EN-US">, </span>교체<span lang="EN-US">, </span>고장<span lang="EN-US">, </span>통신의
두절 등의 사유가 발생한 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">4. </span>제<span lang="EN-US">3</span>자와의 제휴를
통해 서비스가 제공되는 경우<span lang="EN-US">, </span>당해 제휴 사업자의 사정에 따라 변경<span lang="EN-US">/</span>중지되는
경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">5. </span>기타<span lang="EN-US"> '</span>회사<span lang="EN-US">'</span>의 원활한 운영을 현저히 저해하는 사유가 발생한 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">③</span>회사는 제<span lang="EN-US">1</span>항 또는 제<span lang="EN-US">2</span>항의 사유로 서비스 내용이 변경 또는 중단되는 경우 이로 인해 회원이 입은 손해에 대하여 고의 또는 과실이 없는
이상 책임을 부담하지 않습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US" style="font-weight: normal;">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">14</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">서비스
요금</span> </b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">회원은 회사에서 규정한 서비스 요금 정책을 준수해야 하며<span lang="EN-US">, </span>아래 해당하는
서비스 및 손해배상 요금이나 추가 비용이 발생될 수 있습니다<span lang="EN-US">. </span>회원은 회사가 인정하는 방법으로 서비스
및 손해배상 요금을 결제해야 합니다<span lang="EN-US">. </span>자세한 내용을 홈페이지<span lang="EN-US">(APP/WEB)</span>를
통해 게재하며 요금 정책은 테이슨 운영 방침에 따라 변경될 수 있습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①서비스 요금 </span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">1. </span>서비스 요금이라 함은<span lang="EN-US">, </span>서비스
이용 시 발생한 월 사용 요금을 말합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">2. </span>서비스 요금은 서비스 이용 전에 <span lang="EN-US">‘</span>회사<span lang="EN-US">’</span>가 인정하는 방법으로 결제합니다<span lang="EN-US">. </span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">3. </span>회원은 서비스 이용 시 고지된 요금을 지불해야 할 의무가 있습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">4. </span>회원의 개인 신용등급 및 연체 등의 문제로 인해 서비스 요금이
정산되지 않을 경우에는 이전 사용 내역이 모두 정산될 때까지 서비스 이용 자격이 일시 정지되며<span lang="EN-US">, </span>지속적인
문제가 발생할 경우에는 회원 자격이 박탈될 수 있습니다<span lang="EN-US">. </span>이러한 회원의 서비스 사용 권한에 대한 판단
기준은<span lang="EN-US"> '</span>회사<span lang="EN-US">'</span>의 내부 운영 규정에 근거합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">15</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">저작권의
귀속 및 이용제한</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">①</span>회사가 작성한 저작물에 대한 저작권 및 기타 지적재산권은
회사에 귀속합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">②회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제<span lang="EN-US">, </span>송신<span lang="EN-US">, </span>출판<span lang="EN-US">, </span>유튜브 채널<span lang="EN-US">, </span>배포<span lang="EN-US">, </span>방송 등 기타 방법에 의하여 영리 목적으로 이용하거나 제<span lang="EN-US">3</span>자가 이용하도록
하여서는 안됩니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">16</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">게시물의
저작권</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①회원이 서비스 내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">②게시물은 검색결과 내지<span lang="EN-US"> '</span>회사<span lang="EN-US">'</span>의
서비스 및 관련 프로모션 등에 노출될 수 있으며<span lang="EN-US">, </span>해당 노출을 위해 필요한 범위 내에서는 일부 수정<span lang="EN-US">, </span>복제<span lang="EN-US">, </span>편집되어 게시될 수 있습니다<span lang="EN-US">. </span>이 경우 회사는 저작권법 규정을 준수하며<span lang="EN-US">, </span>회원은 언제든지<span lang="EN-US"> '</span>테이슨 고객센터<span lang="EN-US">' </span>또는<span lang="EN-US"> '</span>홈페이지<span lang="EN-US">(APP/WEB)' </span>내 관리 기능을 통해 해당 게시물 등에 대해 삭제<span lang="EN-US">, </span>검색결과
제외<span lang="EN-US">, </span>비공개 등의 조치를 요청 할 수 있습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">③회사는 제<span lang="EN-US">2</span>항 이 외의 방법으로 회원의 게시물을 이용하고자 하는
경우에는 전화<span lang="EN-US">, </span>팩스<span lang="EN-US">, </span>이메일 등을 통해 사전에 회원의 동의를
얻습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">17</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">공개게시물의
삭제</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①회원의 공개 게시물의 내용이 다음 각 항목에 해당되는 경우 회사는 회원에게 사전 통지 없이 공개 게시물을 삭제하거나<span lang="EN-US">, </span>비공개 설정 할 수 있고 해당 회원의 이용 제한<span lang="EN-US">, </span>자격 상실을 시킬
수 있습니다<span lang="EN-US">. </span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">1. </span>회사<span lang="EN-US">, </span>다른 회원
또는 제<span lang="EN-US"> 3</span>자를 비방하거나 중상 모략으로 명예를 손상시키는 내용인 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">2. </span>공서양속에 위반하는 내용의 정보<span lang="EN-US">, </span>문장<span lang="EN-US">, </span>도형 등의 유포에 해당하는 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">3. </span>범죄행위와 관련이 있다고 판단되는 내용인 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">4. </span>회사의 저작권<span lang="EN-US">, </span>제<span lang="EN-US"> 3</span>자의 저작권 등 기타 권리를 침해하는 내용인 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">5. </span>불필요하거나 승인되지 않은 광고<span lang="EN-US">, </span>판촉물을 게재하는 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">6. </span>동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나는
경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US">7. </span>기타 관계법령 및 회사의 지침 등에 위배된다고 판단되는 경우</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">18</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">책임제한</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①회사는 천재지변 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한
책임이 면제됩니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">②회사는 회원의 귀책 사유로 서비스 이용 중 장애가 발생한 경우 책임을 지지 않습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">③회사는 회원이 서비스에 게재한 정보<span lang="EN-US">, </span>자료<span lang="EN-US">, </span>사실의 신뢰도<span lang="EN-US">, </span>정확성에 대하여 회사의 고의 또는 중대한 과실이
없는 한 책임을 지지 않습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">④회사는 회원간 또는 회원과 제<span lang="EN-US">3</span>자 상호간에 서비스를 매개로 한 거래에서
책임이 면제되며 이로 발생된 분쟁에 대해서는 개입할 의무가 없고<span lang="EN-US">, </span>이로 인한 손해를 배상할 책임도 없습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">⑤회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반 행위로 인하여 회사가 회원 이외의 제<span lang="EN-US">3</span>자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의 제기를 받는 경우 회원은 자신의 책임과 비용으로 회사를
면책시켜야 하며<span lang="EN-US">, </span>회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야
합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">⑥<span lang="EN-US">’</span>페이지<span lang="EN-US">(APP/WEB)' </span>및
링크 등으로 연결된 사이트를 운영하는 회사<span lang="EN-US">(</span>이하<span lang="EN-US"> '</span>피연결회사<span lang="EN-US">')</span>는 회사와 별개의 사업자로서 독자적으로 운영됩니다<span lang="EN-US">. </span>회사는 회원과<span lang="EN-US"> '</span>피연결회사<span lang="EN-US">' </span>간에 이루어진 일체의 거래에 대해서 어떠한 책임도 지지
않습니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><b><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">19</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">준거법
및 합의관할</span></b></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">①본 약관에서 지정되지 않은 사항과<span lang="EN-US">, </span>회사와 회원간 제기된 소송은
대한민국법 및 준거법으로 합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">②회사와 회원 간 분쟁이 발생한 경우<span lang="EN-US">, </span>제소 당시 회원의 주소지 기준의
관할 법원으로 하며<span lang="EN-US">, </span>회원의 주소 또는 거소가 명확하지 않을 경우 관할 법원은 민사 소송법에 따라 정합니다<span lang="EN-US">. </span>단<span lang="EN-US">, </span>회원의 책임 사유로 채권 채무가 발생된 경우 그 관리 업무를
담당하는 회사사무소 소재지의 지방 법원을 관할 법원으로 합니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">③회사와 회원 간 분쟁이 발생한 경우<span lang="EN-US">, </span>분쟁이 완료 되는 시점까지
회원의 서비스 제공은 중지 되며<span lang="EN-US">, </span>분쟁 결과에 따라 회원 자격재심사가 이루어집니다<span lang="EN-US">.</span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-size: 10pt;">부칙</span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;">본 약관은<span lang="EN-US"> 2023</span>년 <span lang="EN-US">4</span>월
<span lang="EN-US">26</span>일부터 적용됩니다<span lang="EN-US">.</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all">












































































































































  </p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:120%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span lang="EN-US">&nbsp;</span></p></h5><p></p>
</div>
</template>

<script>
export default {
  name: "TermsOfService"
}
</script>

<style scoped>

</style>